export const BaseUrl = "https://api.rmsoftware.host/api";
// export const BaseUrl = "http://127.0.0.1:8000/api";
export const STORAGE = "https://api.rmsoftware.host/";
export const LOGIN = `${BaseUrl}/login`;
export const CAQTEGORIES = `${BaseUrl}/category`;
export const STORES = `${BaseUrl}/store`;
export const APPROVE_SUBSCRIPTION = `${BaseUrl}/approve-subscription`;
export const DELETE_SUBSCRIPTION = `${BaseUrl}/delete-subscription`;
export const PRODUCTS_STORES = `${BaseUrl}/product?store=`;
export const PRODUCTS = `${BaseUrl}/product`;
export const USERS = `${BaseUrl}/user`;
export const CREATE_USERS = `${BaseUrl}/user/create`;
export const SLIDER = `${BaseUrl}/slider`;
export const POSTS = `${BaseUrl}/posts?StoreId=`;
export const CREATE_POSTS = `${BaseUrl}/posts`;
export const IMAGES_POSTS = `${BaseUrl}/post/image`;
export const VIDEO = `${BaseUrl}/video`;
export const STATICS = `${BaseUrl}/statistic`;
export const PLANS = `${BaseUrl}/plan`;
export const POLICY = `${BaseUrl}/policy`;
export const TERMS = `${BaseUrl}/terms`;
export const CONTACT = `${BaseUrl}/email`;
export const SELLERS = `${BaseUrl}/user?type=seller`;
export const SELLER_STORE = `${BaseUrl}/store/byUserID/`;
export const NOTIFICATION = `${BaseUrl}/admin/notifications/send`;
