import React, { useState, useEffect } from "react";
import {
  Container,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { USERS,NOTIFICATION } from "../../constants/url";
import DeleteUser from "./DeleteUser";
import AddUser from "./AddUser";
import UpdateUser from "./UpdateUser";

const UserPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  // State for notification
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const FetchUser = async () => {
    setLoading(true);
    let params = new URLSearchParams({
      search: searchTerm
    });
    
    // Only add the page parameter if there is no search term
    if (!searchTerm) {
      params.append('page', page);
    }

    try {
      const response = await axios.get(`${USERS}?${params}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 200) {
        // Handle different response structures based on pagination
        if (response.data.data && typeof response.data.data === 'object' && response.data.data.data) {
          // Paginated response
          setInfo(response.data.data.data);
          setTotalPage(response.data.data.last_page);
        } else {
          // Non-paginated response (assuming flat data array)
          setInfo(response.data.data);
          setTotalPage(1); // No pagination available
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/login");
        localStorage.removeItem("token");
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    FetchUser();
  }, [reload, page, searchTerm]);

  // Function to send notification
  const sendNotification = async () => {
    try {
      const response = await axios.post(
        NOTIFICATION, 
        {
          title: notificationTitle,
          body: notificationBody
        }, 
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.status === 200) {
        alert('Notification sent successfully!');
        setOpenDialog(false); // Close the dialog after sending
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      alert('Failed to send notification');
    }
  };
  
  return (
    <>
      <Helmet>
        <title> المستخدمين | Business Iraq </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            المستخدمين
          </Typography>
          <TextField
            label="بحث"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <AddUser setReload={setReload} reload={reload} />

          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            إرسال إشعار
          </Button>

        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">معرف مستخدم</TableCell>
                  <TableCell align="center">اسم</TableCell>
                  <TableCell align="center">رقم هاتف</TableCell>
                  <TableCell align="center">دور</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.map((item) => (
                  <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.phone_number}</TableCell>
                    <TableCell align="center">{item.role_name}</TableCell>
                    <TableCell align="center" className="d-grid">
                      <UpdateUser id={item.id} i={item} setReload={setReload} reload={reload} />
                      <DeleteUser id={item.id} setReload={setReload} reload={reload} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Stack spacing={2} sx={{ padding: 2 }}>
          {totalPages > 1 && !searchTerm && ( // Only show pagination if there are multiple pages and no search term
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              sx={{ "& .MuiPagination-ul": { justifyContent: "center" } }}
            />
          )}
        </Stack>
      </Container>

      {/* Dialog for Sending Notification */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>إرسال إشعار</DialogTitle>
      <DialogContent>
        <TextField
          label="عنوان الإشعار"
          fullWidth
          variant="outlined"
          margin="normal"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
        />
        <TextField
          label="نص الإشعار"
          fullWidth
          variant="outlined"
          margin="normal"
          value={notificationBody}
          onChange={(e) => setNotificationBody(e.target.value)}
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary">
          إلغاء
        </Button>
        <Button onClick={sendNotification} color="primary" variant="contained">
          إرسال
        </Button>
      </DialogActions>
    </Dialog>

    </>
  );
};

export default UserPage;
